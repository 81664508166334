@media screen and (max-width:600px) {
	.social_media_title{
		text-align: center;
text-shadow: none;
font-family: 'proxima-regular','sans-serif'!important;
color: #d57c30;
font-size: 25px;
	}
	.twitter_title{
		margin-top: 10px;
 width: 301px;
border-left: 1px solid #ccc;
border-top: 1px solid #ccc;
border-right: 1px solid #ccc;
background: #55ACEE;
color: white;
text-align: center;
font-size: 30px;
}
.facebook_title{
 width: 301px;
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		border-right: 1px solid #ccc;
		background: #3B5998;
		color: white;
		text-align: center;
		font-size: 30px;
}
.social-feed-widget3{
margin-left: 1px;margin-right: 1px;
margin-bottom: 5vh;
}
	
.facebook_web,.twitter_web{
display:none;
}
.facebook_responsive,.twitter_responsive{
display:block;
}
}
@media screen and (max-width:1100px) {
    
	.social-feed-widget2{
display:block;padding: 17px;box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;
}

}
@media screen and (min-width:1101px) {
	.social-feed-widget2{
display:flex;box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;
}

    
}
@media screen and (min-width:601px) {
    .twitter_title{
        //width: 500px;
           
           border-left: 1px solid #ccc;
           border-top: 1px solid #ccc;
           border-right: 1px solid #ccc;
           background: #55ACEE;
           color: white;
           text-align: center;
           font-size: 30px;
           }
           .facebook_title{
            //width: 500px;
                   border-left: 1px solid #ccc;
                   border-top: 1px solid #ccc;
                   border-right: 1px solid #ccc;
                   background: #3B5998;
                   color: white;
                   text-align: center;
                   font-size: 30px;
           }
	.social-feed-widget3{
margin: 2.3vw;
margin-left: auto;
    margin-right: auto;
}
	.social_media_title{
		text-align: center;
text-shadow: none;
font-family: 'proxima-regular','sans-serif'!important;
color: #d57c30;
font-size: 30px;
	}
// 	.fa {
// padding: 20px;
// font-size: 30px;
// width: 50px;
// text-align: center;
// text-decoration: none;
// margin: 5px 2px;
// }
.facebook_web,.twitter_web{
display:block;
}
.facebook_responsive,.twitter_responsive{
display:none;
}




}
.social-feed-widget {
/* background-color: #f5f5f5;
border: 1px solid #ccc; */
margin-top: 10px;
padding: 10px;
/* border-radius: 5px; */
}
.aiema-card{
    height: 340px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.aiema-image{
    height: 150px !important;
    width: 120px !important;
}

.aiema-first{
    padding: 10px 15px;
    height: 340px;
}

.aiema-first-text{
    padding: 20px 10px;
}

.am-title{
    font-size: 1.5rem;
    font-weight: 300;
    color: #3d5266;
}

.aiema-second{
    padding: 10px 15px;
}

// PRODUCT LISTING
.aiema-card1{
    height: 340px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
    width: 410px;
    margin-left: 10px;
    margin-top: 15px;
}

.aiema-first1{
    padding: 10px 10px;
    height: 340px;
}

.aiema-image1{
    height: 120px !important;
    width: 100px !important;
}

.aiema-first-text1{
    padding: 10px 5px;
    padding-left: 15px;
}

.am-title1{
    font-size: 1.1rem;
    font-weight: 300;
    color: #3d5266;
}

// COMPANY PROFILE

#am-product{
    font-size: 14px;
    font-family: 'proxima-regular','sans-serif' !important;
    .am-page-title {
        color: #1f1f1f;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 5px;
        font-family: 'proxima-regular','sans-serif' !important;
    }

    .container-fluid{
        padding:30px;
    }

    .ampage-header {
        margin-bottom: 1.875rem;
    }

    .card{
        border: 1px solid #ededed;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: fit-content;
    }

    .profile-view {
        position: relative;
    }

    .profile-img-wrap {
        height: 120px;
        position: absolute;
        width: 120px;
        background: #fff;
        overflow: hidden;
    }

    .profile-img {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        position: relative;
    }

    .profile-basic {
        margin-left: 140px;
        padding-right: 50px;
    }

    .profile-info-left {
        border-right: 2px dashed #ccc;
    }

    .user-name {
        color: #333;
    }

    .personal-info {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        padding-top: 15px;
        .li{
            padding-top: 8px;
        }
    }

    .ami-title {
        color: #4f4f4f;
        float: left;
        font-weight: 500;
        margin-right: 30px;
        width: 25%;
    }

    .ami-text {
        color: #8e8e8e;
        display: block;
        overflow: hidden;
    }

    a {
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
    }

    .ai-mt{
        margin-top: 5px;
    }

    .tab-box {
        border-bottom: 0;
        margin-bottom: 10px;
        padding: 0;
    }

    .user-tabs{
        padding: 10px 15px;
    }

    .amcard-title {
        color: #1f1f1f;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

// AIEMW NEW CARD
#aiemacards{
    font-family: 'proxima-regular','sans-serif' !important;
    .aiema{
        min-height: 450px;
        margin: 0px 10px;
        padding: 10px 15px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border: 1px solid #999;
        border-radius: 12px;
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        &-img-holder{
            display:block;
            height: 140px;
            width: 100%;
            display: flex;
            justify-content: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            margin: 0.2px;
        }
        &-img{
            height: 130px;
            width: 130px;
            // border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            // overflow: hidden;
        }
        &-description-holder{
            display: block;
        }
        &-title{
            font-size: 20px;
            font-weight: 550;
            color: rgb(59, 59, 59);
        }
        &-subtitle{
            padding-top: 1px;
            padding-bottom: 6px;
            font-size: 15px;
            font-weight: 500;
            color: rgb(138, 138, 138);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-tabs{
            margin-top: 8px;
            ul{
                display: flex;
                justify-content: center;
            }
            li{
                padding: 6px 9px;
                padding-right: 10px;
                background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);
                border-radius: 50%;
                margin-left: 10px;
                a{
                    i{
                    color: #fff !important;
                    font-size: 20px !important;
                    line-height: 10px !important;
                    width: 20px !important;
                }
            }
            }
            li:hover{
                box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
                cursor: pointer;
            }
            .nav-tabs{
                border-bottom: none !important;
            }
        }
        &-profile{
            margin-bottom: 40px;
            margin-top:15px;
            .profile-title{
                color: rgb(138, 138, 138);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            .profile-description{
                color: rgb(65, 64, 64);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            a{
                font-size: 14px;
            }
        }
        &-contact{
            a{
                font-size: 14px;
            }
            margin-bottom: 40px;
            margin-top:15px;
            .contact-title{
                color: rgb(153, 153, 153);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
            }
            .contact-description{
                color: rgb(65, 64, 64);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
                overflow-wrap: break-word;
            }
        }
        &-submit-btn{
            height: fit-content;
            position: absolute;
            bottom: 15px;
            width: 83%;
            .aiema-btn{
                background: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);
                border: none !important;
                color: #fff;
            }
        }
        &-active{
            .aiema-tabs{
                li{
                    background-image: linear-gradient(to right top, #051937, #051937, #051937, #051937, #051937);
                }
            }
        }
    }
   
}

#aiema-listing{
    position: relative;
    .aiema{
        min-height: 420px;
        max-height: 420px;
        padding: 10px 10px;
        min-width: 300px;
        max-width: 300px;
        margin-bottom: 12px;
    }
    
}

#aiemacards-home{
    font-family: 'proxima-regular','sans-serif' !important;
    .aiema{
        min-height: 450px;
        margin: 0px 10px;
        padding: 10px 15px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border: 1px solid #999;
        border-radius: 12px;
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        &-img-holder{
            display:block;
            height: 140px;
            width: 100%;
            display: flex;
            justify-content: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            margin: 0.2px;
        }
        &-img{
            height: 130px;
            width: 130px;
            // border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            // overflow: hidden;
        }
        &-description-holder{
            display: block;
        }
        &-title{
            font-size: 20px;
            font-weight: 550;
            color: rgb(59, 59, 59);
        }
        &-subtitle{
            padding-top: 1px;
            padding-bottom: 6px;
            font-size: 15px;
            font-weight: 500;
            color: rgb(138, 138, 138);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &-tabs{
            margin-top: 8px;
            ul{
                display: flex;
                justify-content: center;
            }
            li{
                padding: 6px 9px;
                padding-right: 10px;
                background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);
                border-radius: 50%;
                margin-left: 10px;
                a{
                    i{
                    color: #fff !important;
                    font-size: 20px !important;
                    line-height: 10px !important;
                    width: 20px !important;
                }
            }
            }
            li:hover{
                box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
                cursor: pointer;
            }
            .nav-tabs{
                border-bottom: none !important;
            }
        }
        &-profile{
            margin-bottom: 40px;
            margin-top:15px;
            .profile-title{
                color: rgb(138, 138, 138);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            .profile-description{
                color: rgb(65, 64, 64);
                font-size: 14px;
                font-weight: 500;
                text-align: left;
            }
            a{
                font-size: 14px;
            }
        }
        &-contact{
            a{
                font-size: 14px;
            }
            margin-bottom: 40px;
            margin-top:15px;
            .contact-title{
                color: rgb(153, 153, 153);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
            }
            .contact-description{
                color: rgb(65, 64, 64);
                font-size: 13px;
                font-weight: 500;
                text-align: left;
                overflow-wrap: break-word;
            }
        }
        &-submit-btn{
            height: fit-content;
            position: absolute;
            bottom: 15px;
            width: 83%;
            .aiema-btn{
                background: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);
                border: none !important;
                color: #fff;
                width:104%;
            }
        }
        &-active{
            .aiema-tabs{
                li{
                    background-image: linear-gradient(to right top, #051937, #051937, #051937, #051937, #051937);
                }
            }
        }
    }
   
}

.aiema::-webkit-scrollbar {
    display: none;
  }

@media screen and (max-width:600px) {

        #aiemacards{
        font-family: 'proxima-regular','sans-serif' !important;
        .aiema{
            margin-right: 10px;
            padding: 10px 15px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            border: 1px solid #999;
            border-radius: 12px;
            overflow-y: auto;
            overflow-x: hidden;
            &-img-holder{
                display:block;
                position: relative;
                height: 20%;
                width: 30%;
                margin: 0.2px;
                top:-10px;
                transform: scale(0.6);
            }
            &-img{
                height: 130px;
                width: 130px;
                position: relative;
                top:-30px;
                left:-30px;
                // border-radius: 50%;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
                // overflow: hidden;
            }
            &-description-holder{
                display: block;
            }
            &-title{
                font-size: 16px !important;
                font-weight: 600;
                color: rgb(59, 59, 59);
                width: 70%;
                position: relative;
                text-align: initial;
                top:-125px;
                left: 100px;
            }
            &-subtitle{
                padding-top: 1px;
                padding-bottom: 6px;
                font-size: 15px;
                font-weight: 500;
                color: rgb(138, 138, 138);
                width: 70%;
                position: relative;
                text-align: initial;
                top:-125px;
                left: 100px;
                white-space: inherit;
    
            }
            .tab-content{
                margin-top: -100px;
            }
            &-tabs{
                display: none;
                // margin-top: 8px;
                // ul{
                //     display: flex;
                //     justify-content: center;
                // }
                // li{
                //     padding: 6px 9px;
                //     padding-right: 10px;
                //     background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);
                //     border-radius: 50%;
                //     margin-left: 10px;
                //     a{
                //         i{
                //         color: #fff !important;
                //         font-size: 20px !important;
                //         line-height: 10px !important;
                //         width: 20px !important;
                //     }
                // }
                // }
                // li:hover{
                //     box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
                //     cursor: pointer;
                // }
                // .nav-tabs{
                //     border-bottom: none !important;
                // }
            }
            &-profile{
                margin-bottom: 0px;
                margin-top:0px;
                width: 100%;
                
                .profile-title{
                    color: rgb(138, 138, 138);
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                }
                .profile-description{
                    color: rgb(65, 64, 64);
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                }
                a{
                    font-size: 14px;
                }
            }
            &-contact{
                a{
                    font-size: 14px;
                }
                margin-bottom: 40px;
                margin-top:15px;
                .contact-title{
                    color: rgb(153, 153, 153);
                    font-size: 13px;
                    font-weight: 500;
                    text-align: left;
                }
                .contact-description{
                    color: rgb(65, 64, 64);
                    font-size: 13px;
                    font-weight: 500;
                    text-align: left;
                    overflow-wrap: break-word;
                }
            }
            &-submit-btn{
                height: fit-content;
                position: relative;
                top:5px;
                bottom: 15px;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 5px;
                .aiema-btn{
                    background: linear-gradient(to right top, #051937, #004d7a, #008793, #37b4b0, #33e4de);
                    border: none !important;
                    color: #fff;
                    width: 70% !important;
                    display: flex;
                    justify-content: center;
                    font-weight: 500;
                    font-size: 18px
                }
            }
            &-active{
                .aiema-tabs{
                    li{
                        background-image: linear-gradient(to right top, #051937, #051937, #051937, #051937, #051937);
                    }
                }
            }
        }
    }
    #aiema-listing{
        position: relative;
        .aiema{
            min-height: 0px;
            max-height: 1150px;
            padding: 10px 10px;
            min-width: 23.8rem;
            margin-bottom: 12px;
        }
        
    }
}

@media screen and (max-width:390px) {
    #aiema-listing{
        position: relative;
        .aiema{
            margin-left:3%;
            min-height: 0px;
            max-height: 1150px;
            padding: 10px 10px;
            min-width: 21.2rem ;
            margin-bottom: 12px;
        }
        
    }
}



::-ms-input-placeholder {
	color: #808080 !important;
	opacity: 0.5 !important;
}

body {
	// font-family: "Inter-Medium";
	background-color: $theme-bg;
}
a,
a:hover {
	color: $font-color1;
	text-decoration: none;
}
ul {
	padding-left: 0px;
	margin: 0;
}
li {
	list-style: none;
}

h3,
p {
	// font-family: "Inter-Regular";
}

.product-name {
//   font-family: 'proxima-nova';
}
h6 {
	// font-family: 'proxima-nova';
}
h4 {
	// font-family: "Inter-Medium";
	&.bold {
		// font-family: "Inter-ExtraBold";
	}
	&.sm-bold {
		// font-family: "proxima-nova";
		font-size: 14px !important;
	}
}
.pb-50 {
	padding-bottom: 50px;
}

ul {
	&.two-column{
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				margin-right: 15px;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
	&.three-column{
		columns: 3;
		-webkit-columns: 3;
		-moz-columns: 3;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
  }
/** Title **/
.content-title {
	text-align: center;
	padding: 30px 0px 30px 0px;
	.title-box {
		.title {
			display:inline-block;
			position: relative;
			// font-family: 'proxima-nova';
			margin-bottom: 5px;
			font-size: 1.8rem !important;
			color: #d57c30;
			// span {
			// 	background-color: $theme-bg;
			// }
			// &:after {
			// 	content:"";
			// 	width:100%;
			// 	position: absolute;
			// 	bottom:2px;
			// 	height:2px;
			// 	background:#000;
			// 	left:0;
			// 	z-index: -1;
			// }

		}
		.sub-title {
			// font-family: 'Inter-Regular';
			color:#a7a7a7;
			font-size: 1.3rem !important;
		}
	}
}


.row-fluid {
	display: flex;
	flex-flow: wrap;
}
.sub-heading{
	text-transform: uppercase;
    font-size: 17px;
    // font-family: 'Inter-Medium';
    margin-bottom: 20px;
	cursor: pointer;
}


/**Product box one **/
.product-box-one {
	.product-detail {
		background-color: #fff;
		padding:10px;
		position: relative;
		border: 2px solid #eceaea;
		.product-name {
			padding-right:30px;
		}
		.product-option {
			.wishlist {
				.add-to-wishlist {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 22px;
				}
			}
		}
	}
}
/**product**/

.product-name {
	font-size: 18px;
}
h6 {
	&.product-name {
		font-size: 19px;
	}
}

/***Listing**/
.collection-wrapper {
    .collection-content {
      background-color: #fff;
    }
    padding-top: 30px;
    padding-bottom: 30px;
    .collection-top {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .collection-filter {
      background-color: #fff;
      .filter-top {
        border-bottom: 1px solid #dddddd;
        .filter-clear {
          padding: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
      .layerd-navigation {
        padding: 10px;
        // font-family: "Inter-Regular";
		border-bottom: 1px solid #dddddd;
		.collection-collapse-block {
			margin-bottom: 10px;
			
		.collection-collapse-block-content {
			padding-left: 30px;
			
			.options {
                margin: 8px 0px 8px 0px;
                input {
                    margin-right: 10px;
                }
                label{
                    margin-bottom: 0px;
                    font-size: 15px;
                    .count {
                        color: #afabab;
                        font-size: 14px;
                    }
                }
            }
		}
	  }
	}
    }
  
    .col-grid-box {
      width: 100%;
      background: #fff;
      padding: 0px 10px 0px 10px;
    }
  
    .sortby {
      .input-group-text {
        background-color: #fff;
        border-radius: 0;
        border-right: 0;
      }
      .sortby-options {
        border-left: 0px;
        border-radius: 0;
      }
    }
  }
  /** Product Details**/
  
.product-details-wrapper {
    .details-top {
        padding: 15px 0px 15px 0px;
    }
    .slider-nav {
        max-width: 400px;
        min-width: 300px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        
    .owl-thumb {
        img{
            opacity: 0.5;
        }
        background-color: #000;    
        cursor: pointer;
        &:hover {
            background-color: transparent;
            border:2px solid #f7f1f1;
            img {
                opacity: 1;
            }
        }
        &.active {
            
            border:2px solid #f7f1f1;
            background-color: transparent;
            img {
                opacity: 1;
            }
        }
    }
    }
    .product-all-details {
        .product-name {
            font-size: 30px;
        }
    }

    .details-wrapper {
        .product-all-details {
            background-color: #fff;
            padding: 0px 15px 15px 15px;
            .price {
				font-size: 24px;
			}
            .row {
                padding:15px;
                .col-left {
                    flex: 1;
                    padding-right: 15px;
                }
                .col-right{
                    min-width: 250px;
                     .add-to-cart {
                        margin-bottom: 30px;
                    }
                    .delivery-option {
                        margin-top: 30px;
                        h6 {
                            .fa {
                                    font-size: 22px;
                                    -webkit-transform: scaleX(-1);
                                    transform: scaleX(-1);
                            }
						}
						.input-group {
							input {
								border-right:0px;
							}
							.input-group-text {
								background-color: #fff;
								border-left:0;
								color:$primary-color;
							}
						}
						
                    }
                   
                }
            }
            
            .description{
                padding-bottom: 15px;
            }
            .product-addtional-details {
                position: relative;
                padding-top: 15px;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 198px);
                    height: 2px;
                    background: #707070;
                    opacity: 0.2;
                    box-shadow: 0;
                    top: -10px;
                }
            }
        }
        
    }
    
}

.ng5-slider {
	.ng5-slider-pointer {
		cursor: pointer;
		width: 20px!important;
		height: 20px!important;
		top: -8px!important;
		background-color: #fff !important;
		z-index: 3;
		border-radius: 16px!important;
		box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-webkit-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-moz-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
	}

	 .ng5-slider-span:focus{
		outline: 0;
	}
	.ng5-slider-pointer:after {
		display: none !important;
	}
	.ng5-slider-selection {
		z-index: 2;
		background: $primary-color !important;
		border-radius: 2px;
	}
}

/** Pages **/

.homepage-banner-slider {
  .owl-theme {
    .owl-dots { 
      position: absolute;
			bottom: 15px;
			left: 50%;
	}
	.owl-next,
	.owl-prev {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		background-color: $theme-bg !important;
		border: 0px !important;
		.fa {
			font-size: 30px;
		}
	}
	.owl-next{
		right:0;
		background:$theme-bg;
		width:20px;
	}
	.owl-prev{
		left:0;
		background:$theme-bg;
	}
  }
}
.rounder-corner-nav {
	&.top-nav-right{
		.owl-theme {
			.owl-nav {
				position: absolute;
				top: -70px;
				right: 0;
			}
	}
}
&.top-nav-bg-white{
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
					background: #fff;
					border: 0px;
					.fa {
						color:#000;
						
					}
			}
		}
}
}
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
				border-radius: 20px;
				width: 40px;
				height: 40px;
				background: transparent;
				border: 2px solid #b7b6b5;
				.fa {
					color:#b7b6b5;
					font-size: 26px;
				}
			}
		}
	}
}
.rotate-dot{
	.owl-theme {
		.owl-dots {
		
			transform: translateX(-50%);
			[class*="owl-"] {
				width: 25px;
				height: 25px;
				transform: rotate(130deg);
				margin: 0px 6px;
				border: 1px solid transparent;
				&.active {
					border: 1px solid $primary-color;
					span {
						background-color: $primary-color;
					}
				}

				span {
					width: 12px;
					height: 12px;
					border-radius: 0;
					margin: 5px 5px;
				}
			}
		}
	}
}


.featured-collections {
  .tab-image {
          height:300px;
          text-align: center;
          position: relative;
          margin-bottom: 30px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          .background-image {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
                min-height: 300px;
                &:hover {
                  transform: scale(1.2);
                }
          }
          .bg-content{
            position: absolute;
            top:50%;
            left:50%;
			transform: translate(-50%, -50%);
			 color:#fff;
            font-size: 25px;
           
			  p{
				  font-size: 15px;
			  }
           
          }
          
  }
}


.best-products {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 30px;
  .title {
	  text-align: center;
	  padding-bottom: 20px;
	   h2 {
		// font-family: 'proxima-nova';
	}
  }
  .column {
      
      -webkit-flex: 1; /* Safari */
      -ms-flex: 1; /* IE 10 */
      flex: 1; /* Standard syntax */
      
      &.grid-image {
          -webkit-flex: 2; /* Safari */
          -ms-flex: 2; /* IE 10 */
          flex: 2; /* Standard syntax */
      }
      &.single-image {
          border: 1px solid #c5c5c5;
          margin-bottom:20px;
          text-align: center;
          .trers-single-image {
              img {
                  min-width: 300px;
              }
          }
          h4 {
              font-size: 20px;
              text-transform: uppercase;
          }
      }
      .tyers-grid {
          display: flex;
          flex-flow: wrap;
          margin: 0;
          li {
              width:calc(50% - 20px);
              border: 1px solid #c5c5c5;
              margin: 0px 20px 20px 0px;
              padding:10px;
              display: flex;
			  align-items: center;
			  .trers-grid-image {
				  img {
					  padding: 10px;
				  }
			  }
              a {
				cursor: pointer;
				display:block;
              }
              &:hover {
                border-color: var(--theme-deafult);
              }
              h4 {
                  font-size: 20px;
                  text-transform: uppercase;
              }

          }
      }
    
      
  }
 
}


@media screen and (max-width:600px) {

	.featured-collections {
		.tab-image {
				height:100px;
				text-align: center;
				position: relative;
				margin-bottom: 30px;
				cursor: pointer;
				overflow: hidden;
				position: relative;
				.background-image {
					position: relative !important;
				  min-height: 100px;
				  width: 120%;
				  margin-left: -10%;
				 
				  background-repeat: no-repeat;
				  -webkit-transition: all .5s;
				  -moz-transition: all .5s;
				  -o-transition: all .5s;
				  transition: all .5s;
					  &:hover {
						transform: scale(1.2);
					  }
				}
				.bg-content{
				  position: absolute;
				  top:50%;
				  left:50%;
				  transform: translate(-50%, -50%);
				   color:#fff;
				  font-size: 25px;
				 
					p{
						font-size: 15px;
					}
				 
				}
				
		}
	  }

	  .rounder-corner-nav {
		&.top-nav-right{
			.owl-theme {
				.owl-nav {
					position: absolute;
					top: -70px;
					right: 0;
				}
		}
	}
	&.top-nav-bg-white{
		.owl-theme {
			background-color: transparent !important;
			.owl-nav {
				margin-top:0px;
				background-color: transparent !important;
				.owl-prev,
				.owl-next {
						background: transparent !important;
						border: 0px;
						.fa {
							color:#000;
							
						}
				}
			}
	}
	}
		.owl-theme {
			.owl-nav {
				margin-top:0px;
				z-index: -100;
				position: absolute;
				top:45%;
				display: flex;
				width: 100%;
				text-align: center;
				justify-content: center;
				top:45%;
				.owl-next {				
					position: relative;
					margin-left: 85%;
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
				.owl-prev{
					position: relative;
					margin-left: -5%;	
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
			}
		}
	}

	.desktop-carousel{
		display: none !important;
	}
	.desktop-only{
		display: none !important;
	}
	.mobile-options{
		position: absolute !important;
    	margin-top: -62px !important;
    	margin: 0;
    	margin-left: 160px;
    	left: 0;
    	border: none !important;
	}
	.mob-eye{
		margin-left:40px
	}
	.mob-second{
		margin-top:-31px
	}
	.forgot-link{
		margin-top:-26px !important
	}
    .forgot-link-checkout{
		margin-top:-45px !important
	}
	.back-btn{
		width:35% !important;
		margin-left:65%;
	}
}

