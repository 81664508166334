.profile-page {
    .heading{
        font-size: 24px;
      }
    
    .form-control{
        background-color: white ;
     }     
     
     .form-label{
        padding-top: 4px;
       
    }
    
    .form-control.edit {
        font-weight: bolder;
        color: $white !important;
        background-color: $pallet2 !important;
      }
    
    small{
      font-size: 90%;
    }
    
    p{
        padding-top: 10px;
        align-items: center;
        padding-left: 90px;
    }
    
    input[type=text]:focus,input[type=email]:focus,input[type=password]:focus {
        border:2px,solid;
      
      }
    
    .custom-select {
      background-color:#dadada;
    }
     .pad{
       margin-right: -70px;
     }
}
  