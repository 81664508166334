.forgot-page{
    .box {
    position: relative;
    margin: 30px auto;
    padding: 20px 20px 20px;
    width: 450px;
    height: auto;
    background: white;
    border-radius: 7px;
    border-color:	#D3D3D3;
    border-width: thin;
    border-style: solid;
    font-size: 14px;
  }
  .heading{
    font-size: 24px;
  }
  
  .ecbee-logo{
    width: 250px;
    height: 100px;
    @include media-breakpoint-down(sm) {
      width: 250px;
      height: 70px;
      background-color: #ffffff;
      margin-top: 10px;
    }
  }
  h1{
    font-weight: bold;
    color:#010203;
    letter-spacing: 1px;
}

.form-control{
    background-color: white ;
 }  
 .loginLink{
  cursor: pointer;
}   

small{
  font-size: 90%;
}

p{
    padding-top: 10px;
    align-items: center;
    padding-left: 90px;
}

input[type=text]:focus,input[type=email]:focus,input[type=password]:focus {
    border-color:#ece3e3;
    border:2px,solid;
  
  }

.custom-select {
  background-color:#dadada;
}
 .pad{
   margin-right: -70px;
 }

.help-text{
  font-size: 0.8rem;
  color: #7c7c7c;
}
.reveal-if-active {
    opacity: 1;
    transition: 0.5s;
    // transform: scale(1);
    overflow: visible;
  }
  
  .hide-if-inactive{
    opacity: 0;
    max-height: 0;
    transition: 0.5s;
    transform: scale(0.8);
    overflow: hidden;
  }
}