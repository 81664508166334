
 .variantBag{
    .product-weight-card{
        padding: 2px 10px !important;
        border: 1px solid $primary !important;
        margin: 3px !important;
        border-radius: 50%;
        // box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
        color: $primary !important;
        cursor: pointer !important;
    }
}


div.checkRadioContainer > label > input {
  display:none
}

div.checkRadioContainer {
  max-width: 10em;
}
// div.checkRadioContainer > label {
//   padding: 2px 10px !important;
//   border: 1px solid $primary !important;
//   margin: 3px !important;
//   border-radius: 50%;
//   // box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
//   color: $primary !important;
//   cursor: pointer !important;
// }

div.checkRadioContainer > label > span {
  display: inline-block;
  vertical-align: top;
  line-height: 2em;
}

div.checkRadioContainer > label > input + span {
  visibility: visible;
  padding: 2px 10px !important;
  border: 1px solid $primary !important;
  margin: 3px !important;
  border-radius: 50%;
  // box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
  color: $primary !important;
  cursor: pointer !important;
}

div.checkRadioContainer > label > input:checked + span {
  visibility: visible;
  background-color: $primary !important;
  border: 1px solid $primary !important;
 color: $white !important;
 box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}



.variantBag.active
{
    background-color: $primary !important;
     border: 1px solid $primary !important;
    color: $white !important;
    box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
    
}
.product-listing-container{
    margin: 10px 20px !important;
}

.product-list-card{
    background-color: #f4f8f98a !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    border: none !important;
    height: auto !important;
}

.review-list-card{
    background-color: #ffffff !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none !important;
    height: 400px !important;
}

.product-list-image{
    min-height: 100% !important;
    max-height: 100% !important;
    width: 80% !important;
    border-radius: 10px !important;
    object-fit: contain;
}
@media (min-width: 360px) and (max-width: 600px) {
    .product-list-image{
      
        border-radius: 10px !important;
        object-fit: contain!important;
      
         min-height: 150px !important;
            max-height: 150px!important;
            width: 100%!important;
            height: 150px!important;
            
    }

}
.product-list-description{
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
}
@media only screen and (max-width: 768px) {
    .product-list-description{
        float: left;
        width: 100%;
        padding: 10px 15px !important;
        margin-bottom: 10px !important;
        position: relative;
        top: 25px;
    }

}
.carience-detail-cart{
    white-space: nowrap;
    width: fit-content;
    position: relative;
    left: 60px!important;
    margin-top: 8px;
    background-color: transparent!important;
    border: 3px solid #3c707c!important;
    color: #091214!important;
    font-size: small;
    top: 10px!important;
}

.vimpro-detail-cart{
    white-space: nowrap;
    width: fit-content;
    position: relative;
    left: 60px!important;
    margin-top: 8px;
    background-color: transparent!important;
    border: 3px solid #FEAE01!important;
    color: #091214!important;
    font-size: small;
    top: 10px!important;
}

.avon-detail-cart{
    white-space: nowrap;
    width: fit-content;
    position: relative;
    // left: 60px!important;
    margin-top: 8px;
    background-color: transparent!important;
    border: 3px solid #f33601!important;
    color: #091214!important;
    font-size: small;
    top: 10px!important;
}

.product-list-tile{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.product-list-price{
    font-size: 16px !important;
    color: #d54604 !important;
    font-weight: 600 !important;
}

.product-weight-card{
    padding: 2px 10px !important;
    border: 1px solid $primary !important;
    margin: 3px !important;
    border-radius: 50%;
    // box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
    color: $primary !important;
    cursor: pointer !important;
}

.product-weight-card:hover{
    background-color: transparent !important;
    color: $white !important;
    box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}

.product-list-cart{
    font-size: 13px !important;
    // color: #255763 !important;
    color: $primary !important;
    font-weight: bold !important;
    cursor: pointer;
    // background-color: #255763 !important;
    // border-radius: 10px !important;
    // padding: 10px 25px !important;
}
@media (max-width: 640px) {
    .avon-detail-cart{
        white-space: nowrap;
        width: fit-content;
        position: relative;
        left: 0px!important;
        margin-top: 8px;
        background-color: transparent!important;
        border: 3px solid #f33601!important;
        color: #091214!important;
        font-size: small;
        top: 10px!important;
    }
    }