@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";


.contact-section{
    max-width: 230px !important;
}

.custom-point{
    cursor: pointer;
    font-size: 14px !important;
}
.custom-point1{
    cursor: pointer;
    font-size: 14px !important;  
    color: #375673 !important;
    font-weight:450
}
.custom-point1:hover{
    background-color:#817979;
    color: #ddd !important;
}
h3,h4{
    text-transform: none !important;
}
h3{
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
    }
}

.shadow {
    -moz-box-shadow:    inset 0 0 10px #9e9797;
    -webkit-box-shadow: inset 0 0 10px #9e9797;
    box-shadow:         inset 0 0 10px #9e9797;
    @include media-breakpoint-down(sm) {
        font-size: 0.7rem;
    }
 }

 .visit-store {
    background: $pallet2;
    padding: 25px 10px 25px 10px;
    ul {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                margin-right: 20px;
                .btn {
                        border: 1px solid #cccccc;
                }
                h3 {
                    text-transform: uppercase;
                    color:#fff;
                    margin: 0;
                }
            }
    }
}

 footer {

   
    background-color: #fff;    
    &.container-fluid.footer {
        border-bottom: 1px solid #ddd;
    }
    .footer-links {
        display:flex;        
        justify-content: space-between;
        padding: 30px 0px;
        
        .footer-col {
            max-width: 200px;
            &.address {
                i {
                    color: $pallet2;
                    margin-right: 5px;
                }
            }
            h4 {
                text-transform: uppercase;
                font-size: 16px;
            }
            p,a {
                font-size: 14px;
            }
            ul {
                li {
                    margin-bottom: 3px;
                }
            }
        }
        
    }
    .footer-row-two {
        padding:30px 15px 10px 10px;
        ul {
            display: flex;
            justify-content: center;
            li {
                margin:5px 10px 5px 10px;
                i {
                    color: $pallet2;
                    font-size: 22px;
                }
            }
        }
    }
    .footer-row-two1 {
        padding:0px 178px 15px 0px;
        ul {
            display: flex;
            justify-content: center;
            li {
                margin:5px 10px 5px 10px;
                i {
                    color: #ffffff;
                    font-size: 22px;
                }
            }
        }
    }
    .copy {
        text-align: center;
        font-size: 14px;
    }
}
/*** Nav **/
.navbar-row {
  box-shadow: 0 3px 4px -5px #333;
}

@media screen and (max-width: 600px) {
    .visit-store{
        ul{
            li{
                h3{
                    width: 120px;
                }
            }
        }
    }
}