table {
    width: 100%;
}

.table td,
.table th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: none;
}

.inq-table-st {
    border-collapse: collapse;
    background: $white;
    margin-bottom: 0!important;
}

.inq-table-layout-st {
    overflow: auto;
    margin-bottom: 4px;
    transition: all .3s ease-in-out;
}

.table-striped tbody tr:nth-of-type(odd) {
    background: transparent !important;
}