.product-customize{
    padding: 20px 30px;
    min-height: 50vh;
    margin-top: 10px !important;
}

.menu-container{
    // padding-right: 15px;
    // border-right: 1px solid #b1afaf;
}

.menu-content{
    padding-bottom: 12px;
}

.menu-item{
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px;
}

.sub-menu-container{
    padding-right: 18px;
}

.sub-menu-item{
    font-size: 14px;
    color: rgb(83, 82, 82);
    cursor: pointer;
    padding-bottom: 3px;
}

.menu-item:hover{
    cursor: pointer;
    color: $menu-txt-color;
}

.sub-menu-item:hover{
    cursor: pointer;
    color: $menu-txt-color;
}

.sub-selected-item{
    color: #000;
    font-weight: 500;
}

.hide-menu{
    height: 0px;
    visibility: hidden;
    transition: visibility height 2s;
}

.show-menu{
    visibility:visible;
    height: auto;
    transition: visibility 3s;
}

.selected-item{
    color: $menu-txt-color;
    font-weight: 600;
    font-size: 18px;
}

.category-icon{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border:1px solid #777 !important;
    padding: 4px;
    margin-right: 3px;
}

.category-icons{
    border:1px solid $menu-txt-color !important;
}

.single-category{
    padding-right: 14px;
}

.menus-title{
    font-size: 32px;
    color: $menu-txt-color;
    font-weight: 200 !important;
    padding-top: 4px;
}

.selected-icon{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border:1px solid $menu-txt-color !important;
    padding: 4px;
    margin-left: 8px;
}

.category-products-container{
    padding-left: 25px;
    border-left: 1px solid #dad9d9;
}

.product-list-container{
    padding-top:15px;
    padding-right: 10px;
}

.category-product-card{
    padding-right: 15px;
    border-bottom:1px solid rgb(218, 218, 218) !important;
    padding-bottom: 10px;
    padding-top: 10px;
}

.cp-title{
    font-size: 16px;
    font-weight: 500;
    color: #3e4152;
    word-break: break-word;
    padding-bottom: 6px;
    padding-top:6px;
}

.rating-text{
    font-size: 14.5px;
    color: rgb(139, 139, 139) !important;
}

.cp-price{
    padding-top: 8px;
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 15px;
    color: rgb(49, 49, 49);
}

.cp-description{
    color: rgba(40,44,63,.45);
    font-size: 13.5px;
    padding-bottom: 10px;
}

.cp-image{
    width: 118px !important;
    height: 96px !important;
    border-radius: 6px;
}

.cp-button-container{
    position: absolute;
    bottom: 8px;
    width: 96px;
    min-height: 36px;
    line-height: 36px;
    left: 10%;
    width: 80%;
    top:76%;
}

.cp-image-container{
    min-width: 118px;
    min-height: 96px;
    max-width: 118px;
    max-height: 96px;
    border-radius: 6px;
    margin-top:10px;
    margin-bottom: 15px;
    position: relative;
}

.cp-button{
    padding:2px 15px;
    border-radius: 5px;
    border: 1px solid $menu-txt-color !important;
    background-color: #ffffff;
    width: 100% !important;
    color: $menu-txt-color !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
    font-weight: 500 !important;
    font-size: 15px;
}

.cp-sm-btn{
    padding:0px 10px;
    border-radius: 5px;
    border: 1px solid $menu-txt-color !important;
    background-color: #ffffff;
    color: $menu-txt-color !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
    font-weight: 500 !important;
    font-size: 10px !important;
}

.customise-text{
    font-size: 11px !important;
    color: rgb(139, 139, 139) !important;
}

.m-content{
    padding: 10px 30px 0 30px;
}

.title-container{
    padding-bottom: 20px;
    border-bottom: 1px dashed #bebfc5;
}

.m-title{
    font-weight: 500;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 490px;
}

.enquiry-title{
    font-family: 'proxima-regular','sans-serif'!important;
    font-weight: 600 !important;
    color: #D57C30!important;
    font-size: 22px!important;
    white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
    word-break: break-word;
    white-space: normal;
    white-space: -webkit-pre-wrap;
}

.m-price{
    margin-top: 7px;
    color: #535665;
    font-size: 15px;
}

.options-container{
    margin-top: 10px;
    padding-bottom: 20px;
}

.options-title{
    font-size: 18px;
    color: rgb(65, 65, 65);
    font-weight: 400 !important;
    padding-top: 4px;
    padding-bottom: 10px;
}

.option-product{
    margin-left: 9px !important;
    font-size: 15px !important;
    font-weight: 200 !important;
}

input.largerCheckbox {
    margin-top: 5px;
    width: 16px;
    height: 16px;
}

input.largerCheckbox:hover {
    font-weight: 500;
}

.addon-container{

}

.m-total{
    font-size: 18px;
    font-weight: 300 !important;
    padding-right: 25px !important;
    color: $menu-txt-color;
}

.m-btn{
    color: #ffffff !important;
    background-color: $menu-txt-color !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    padding: 6px 20px !important;
}

.cart-item-name{
    font-size: 12px;
    font-weight: 500;
}

.customize-txt{
    cursor: pointer;
    font-size: 11px;
    color: rgb(139, 139, 139) !important;
}

.cart-container{
    padding-top: 15px;
}

.error-img{
    height: 320px;
    width: 75%;
    margin-bottom: 15px;
}

.custom-btn{
    padding: 5px 30px !important;
    border-radius: 20px !important;
    margin-top: 10px !important;
    background-color: $menu-txt-color !important;
    border: none !important;
}

.customize-empty-cart{
    height: 210px;
    width: 260px;
    margin-bottom: 15px;
}

@media  screen and (max-width:600px) {
    .enquiry-title{
        white-space: -webkit-pre-wrap;
        font-size:20px !important;
    }
   
    .m-content{
        padding: 0 !important;
    }
    
}