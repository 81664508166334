// body {
//     // background-image: linear-gradient(#4A148C, #E53935);
//     background-repeat: no-repeat;
//     // color: #000;
//     overflow-x: hidden
// }

a {
    text-decoration: none !important;
    color: inherit
}

a:hover {
    color: #455A64
}

.card3 {
    border-radius: 5px;
    background-color: #fff;
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    height : auto;
    border : 0.2px solid lightgray
}

.rating-box {
    width: 130px;
    height: 130px;
    margin-right: auto;
    margin-left: auto;
    background-color: #FBC02D;
    color: #fff
}

.rating-label {
    font-weight: bold
}

.rating-bar {
    width: 300px;
    padding: 8px;
    border-radius: 5px
}

.bar-container {
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 5px
}

#bar-5 {
    // width: 70%;
    height: 13px;
    background-color: #FBC02D;
    border-radius: 20px
}

#bar-4 {
    // width: 30%;
    height: 13px;
    background-color: #FBC02D;
    border-radius: 20px
}

#bar-3 {
    // width: 20%;
    height: 13px;
    background-color: #FBC02D;
    border-radius: 20px
}

#bar-2 {
    // width: 10%;
    height: 13px;
    background-color: #FBC02D;
    border-radius: 20px
}

#bar-1 {
    // width: 0%;
    height: 13px;
    background-color: #FBC02D;
    border-radius: 20px
}



td {
    padding-bottom: 10px
}

.star-active {
    color: #FBC02D;
    margin-top: 10px;
    margin-bottom: 10px
}

.star-active:hover {
    color: #F9A825;
    cursor: pointer
}

.star-inactive {
    color: #CFD8DC;
    margin-top: 10px;
    margin-bottom: 10px
}

.blue-text {
    color: #0091EA
}

.content {
    font-size: 18px
}

.profile-pic {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    margin-right: 30px
}

.pic {
    width: 80px;
    height: 80px;
    margin-right: 10px
}

.vote {
    cursor: pointer
}

// .rating {
//     margin-left: 35px;
//     // padding-left: 6px;
//     // text-align : center;
//     // float:left;
//   }

.rating{

    margin-right: auto;
    margin-left: auto;

}

  .rating:not(:checked) > input {
      position:absolute;
      top:-9999px;
      clip:rect(0,0,0,0);
  }

  .rating:not(:checked) > label {
      float:right;
      width:1em;
      /* padding:0 .1em; */
      overflow:hidden;
      white-space:nowrap;
      cursor:pointer;
      font-size:200%;
      /* line-height:1.2; */
      color:#ddd;
  }

  .rating:not(:checked) > label:before {
      content: '★ ';
  }

  .rating > input:checked ~ label {
      color: #FBC02D;
      
  }

  .rating:not(:checked) > label:hover,
  .rating:not(:checked) > label:hover ~ label {
      color: #FBC02D;
      
  }

  .rating > input:checked + label:hover,
  .rating > input:checked + label:hover ~ label,
  .rating > input:checked ~ label:hover,
  .rating > input:checked ~ label:hover ~ label,
  .rating > label:hover ~ input:checked ~ label {
      color: #FBC02D;
      
  }

  .rating > label:active {
      position:relative;
      top:2px;
      left:2px;
  }

  .rating1{

    margin-right: auto;
    margin-left: auto;

}

  .rating1:not(:checked) > input {
      position:absolute;
      top:-9999px;
      clip:rect(0,0,0,0);
  }

  .rating1:not(:checked) > label {
      float:right;
      width:1em;
      /* padding:0 .1em; */
      overflow:hidden;
      white-space:nowrap;
      cursor:pointer;
      font-size:100%;
      /* line-height:1.2; */
      color:#ddd;
  }

  .rating1:not(:checked) > label:before {
      content: '★ ';
  }

  .rating1 > input:checked ~ label {
      color: #FBC02D;
      
  }

  .rating1:not(:checked) > label:hover,
  .rating1:not(:checked) > label:hover ~ label {
      color: #FBC02D;
      
  }

  .rating1 > input:checked + label:hover,
  .rating1 > input:checked + label:hover ~ label,
  .rating1 > input:checked ~ label:hover,
  .rating1 > input:checked ~ label:hover ~ label,
  .rating1 > label:hover ~ input:checked ~ label {
      color: #FBC02D;
      
  }

  .rating1 > label:active {
      position:relative;
      top:2px;
      left:2px;
  }

  .inner-review img {
    position: relative;
    max-width: 74%;
}
.inner-review p {
    left: 18%;
    position: absolute;
    top: 17%;
}
.inner-review{position:relative;}

.checked {
    color: #fdb915;
}
.unchecked {
    color: green;
    font-size: 12px;
}

@media screen and (max-width:600px) {
    .card3 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}