.checkout-container{
    background-color: #f1eded !important;
    min-height: 100vh;
    .headings{
        font-size: 14px;
        color: $white;
        font-weight: 500;
        text-transform: uppercase;
        // padding-left: 15px;
    }
    .normalText{
        font-size: 14px;
    }

    .accordian-container{
        margin: 10px 30px 10px 10px;
    }

    .custom-accordian{
        .card{
            margin:20px 0px;
            height: auto !important;
            border: none;
            background-color: $white !important;
            // padding: 15px 10px 10px 20px;
        }
        .havencard{
            margin:20px 0px;
            height: auto !important;
            border: none;
            background-color: $white !important;
            // padding: 15px 10px 10px 20px;
        }
        .card-header{
            background-color: $primary;
            color: $white;
            font-size: 16px;
        }
    }
    .hide-btn{
        display: none;
    }
    small{
        font-size: 70%;
    }
}

.checkout-alerts{
    top:60px;
    position:fixed;
    width:70%;
    z-index: 99;
    margin-left: 10px;
  }

.checkout-empty-cart{
    color: $danger !important;
    height: 65px;
    width: 100px;
    margin-bottom: 15px;
}

.checkout-para{
    color: $light-ash;
    font-size: 14px !important;
    margin-top: 15px;
    margin-bottom: 10px !important;
}

.pac-container { z-index: 10000 !important; }

.hidePayment{
    visibility: hidden;
}

@media screen and (max-width:600px) {
    .mob-checkout{
        position:relative;
        top:-102px;

    }
    .checkout-container{
        .accordian-container{
            margin: 10px 10px 10px 10px !important;
        }
    }    
}