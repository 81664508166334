li {
    list-style: none;
}

.inner-html-list{
    
   li{
        list-style: circle;
        
   }
}


p {
    margin-bottom: 0rem !important;
}

a {
    color: $pallet2 !important;
}

.inq-primary {
    color: $primary;
}

.inq-secondary {
    color: $pallet2 !important;
    font-weight: 500;
}

.inq-bg-primary {
    background: $primary;
}