#sf-faq{
    padding: 10px 50px 30px 55px;
    .accordion{
        min-width: 80%;
        max-width: 80%;
        margin-top: 30px;
    }
    .card{
        border: none;
        height: auto;
        margin-bottom: 15px;
        .card-body{
            border: 1px solid $signup-ft-bg;
        }
    }

    .arrow {
        border: solid $form-label-color;
        border-width: 0 1.8px 1.8px 0;
        display: inline-block;
        padding: 5px;
      }
      .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      .menu-arrow{
        float: right;
        margin-right: 15px;
        // margin-top: 2px;
      }
      .btn:focus{
          box-shadow: none;
      }
}

.sf-breadcrumb{
    color: $light-ash;
    font-size: 1rem;
}
