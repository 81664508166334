@import "../../../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.logo-header{
    font-size: 35px;
    margin-bottom: 0px !important;
    color: $form-header;
}

.sub-header{
  font-size:25px!important;
  margin-bottom: 0px !important;
  color: $form-header;
}

.required
{
    color: $red;
}

.form-label{
    float: right;
    margin-right: 10px;
    padding-top: 4px;
    color: #333333;
    @include media-breakpoint-down(sm){
        float: left;
        margin-right: none;
        padding-top: 0px;
    }
}

.buttons{
  margin-top: 25px;
  padding: 3px 45px;
    @include media-breakpoint-down(sm){
        margin-top: 15pxpx;
    }
}

.form-control{
    background-color: $white !important;
}

select {
    &.form-control {
      padding: .4375rem .75rem;
      border: 0;
      outline: 1px solid $border-color;
      color: $input-placeholder-color;
      color: #495057;
      &:focus {
        outline: 1px solid $border-color;
      }
      @each $color,
      $value in $theme-colors {
        &.border-#{$color} {
          outline: 1px solid $value;
          &:focus {
            outline: 1px solid $value;
          }
        }
      }
    }
  }

  .gift-form{
    margin-bottom: 50px;
    padding: 35px 15px;
    -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
    box-shadow: 0 0 4px rgb(218, 216, 216);
    border-radius: 5px;
    width: 100%;
    background-color: $white;
    @include media-breakpoint-down(sm){
        padding: 10px 30px;
        align-items: center;
        margin-bottom: 20px;
    }
}

  .card1{
    // margin-top: 10px;
    margin-left: 30px;
    display: inline-block;
    //margin: 0 auto;
    padding: 4rem 1rem 7rem 1rem;
    background-color: $info-bg;
    position: relative;
    &:after{
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      background-color: $white;
      top: 0px;
      right: 0px;
      border-bottom: 20px solid darken($info-card, 5%);
      border-left: 20px solid darken($info-card, 5%);
      border-right: 20px solid $white;
      border-top: 20px solid $white;
      position: absolute;
      filter: drop-shadow(-5px 5px 3px rgba(94, 94, 94, 0.5));
    }
    &:before{
      content: "";
      display: block;
      width: 0px;
      height: 0px;
      border-top: 40px solid darken($info-card, 5%);
      border-right: 40px solid darken($info-card, 5%);
      border-left: 40px solid $white;
      border-bottom: 40px solid $white;
      bottom: 0px;
      left: 0px;
      position: absolute;
      filter: drop-shadow(7px -7px 5px rgba(94, 94, 94, 0.5));
      margin-right: 10%;
    }
    &:nth-of-type(1){
      margin-right: 9%;
    }
    h2{
      margin-bottom: 1rem;
      font-weight: 400;
      letter-spacing: 1px;
  }
  p{
      font-size: 1.1rem;
      line-height: 140%;
  }
  
  .card-txt{
    color: $primary !important;
  }
  }
