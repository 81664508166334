.wrapper > .wrapper {
  margin-left: 10px;
  font-size: calc(100% - 5%);
}

.sub-list{
  cursor: pointer;
}
.sub-list2{
  cursor: pointer;
}

.sub-list.active {
  font-weight: bold;
  // color: #E31E25 !important;
  color: #FBCB0C !important;
  // color: orange !important;
}
.sub-list2.active {
  font-weight: bold;
  // color: #E31E25 !important;
  color: #FBCB0C !important;
  // color: orange !important;
}


.sub-list1{
  cursor: pointer;
}

.sub-list1.active {
  font-weight: bold;
  // color: #E31E25 !important;
  color: #ed98d2 !important;
  // color: orange !important;
}

.sublist1-font{
  font-size: 14px !important;
  :hover{
    color: #ed98d2 !important;
  }
}

.sub-list-mob-aiema{
cursor: pointer;
}

.sub-list-mob-aiema.active{
font-weight: 900 !important;
// font-size: 18px !important;
border-bottom:1px solid #D57C30;
}

h5{
  font-size: 16px !important;
  text-transform: lowercase;
}

h5:first-line {
  text-transform: capitalize;
}

.sublist-font{
    font-size: 14px !important;
    :hover{
      color: #FBCB0C !important;
    }
}

.titleproper{
  text-transform: lowercase;
}

.titleproper:first-line {
  text-transform: capitalize;
}

.checkbox-container{
  font-size: 15px !important;
}

.arrow-size{
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (max-width:600px) {
  #collapseFilter{
    transition: none;
  }
  
}