.faq-page{
    
.accordion {
    background-color: #eee;
    color: $form-label-color;
    border: 1px solid $light-ash;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
}

.active, .accordion:hover {
    background-color: #ccc;
}

.accordion:after {
    content: '\002B';
    color: $light-ash;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.active:after {
    content: "\2212";
}

.panel {
    padding: 0 18px;
    background-color: $white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
form.example {
  margin : 0 auto;
  max-width : 600px; 
  padding: 4px;

}
form.example input[type=text] {
  
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    padding: 10px;
    // font-size: 17px;
    border: 1px solid $light-ash;
    float: left;
    width: 80%;
    background: $white;
    border-radius: 10px;
  }
  form.example::after {
    content: "";
    clear: both;
    display: table;
  }
  form.example1 {
    margin : 0 auto;
    max-width : 700px; 
    padding: 4px;
    height:300px;
    min-width: 700px;
  
  }
  form.example1 input[type=text] {
    
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      padding: 10px;
      // font-size: 17px;
      border: 1px solid $light-ash;
      float: left;
      width: 80%;
      background: $white;
      border-radius: 10px;
    }
    form.example1::after {
      content: "";
      clear: both;
      display: table;
    }
  @media (min-width: 1300px) {
    .container {
      max-width: 1000px;
    }
    }
}