@import "../../../../../node_modules/bootstrap/scss/bootstrap.scss";

agm-map {
    height: 100%;
    width: 100%;
  }

.btn-outline-success {
  color: $footer-bg !important;
  border-color: $footer-bg !important;
  
}
.btn-outline-success:hover {
  color: $white !important;
  background-color: $footer-bg !important; 
}

.custom-font{
  color: $light-ash;
  font-size: 0.8 rem;
}

