.order-summary{
    .summary-card{
        margin: 20px 0px !important;
        height: auto !important;
        background-color: $white !important;
    }
    .summary-header{
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: $light-ash;
        padding: 1rem 1rem 0px 1rem;
    }
    .summary-container{
        border-top: solid 1px $md-border;
        // border-bottom: solid 1px $md-border;
        padding: 15px 1rem !important;
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .summary-label{
        p{
            font-size: 14px !important;
        }
    }
    .card-body{
        padding: 0px !important;
    }

    .summary-row{
        padding: 8px 0px !important;
    }

    .summary-total-show{
        font-weight: bold;
        // margin:5px 0px;
        border-top: 1px solid $md-border;
    }
}