#myorders{
    padding:15px 60px 20px 40px;
    background-color: $info-bg;
    .filter-label{
        font-size: 1.1rem !important;
        color: #212529 !important;
    }
}

.filter-section{
    background-color: $white;
    border-radius: 5px;
    padding: 10px 10px 10px 25px;
    .filter-list-section{
        
    }
}

.filter-heading{
    font-size: 1.1rem;
    font-weight: 600;
    padding: 5px 0px;
}

.filter-subheading{
    font-size: 0.8rem;
    font-weight: 600;
}