
.qr-product-title{
    color:$primary;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}
.qr-instruction{
    color: $primary;
    font-weight: 300;
    font-style:bold;
    text-transform: uppercase;
}