.header-section{
    top:0px;
    width: 100%;
    background-color: $primary;
    min-height: 55px;
    @include media-breakpoint-down(sm){
        margin-top:55px;
        min-height: auto;
    }
}

.logo-section{
    margin-left: 150px;
    padding: 5px 0px;
    @include media-breakpoint-down(sm){
        margin-left: 10px; 
    }
}

.logo-images{
    width: 100px;
    cursor: pointer;
    @include media-breakpoint-down(sm){
        width: 50px;
        height: 35px;
    }
}

.banner-section{
    margin-left: 30px;
    margin-top: 15px;
    @include media-breakpoint-down(sm){
        margin-left: 5px;
        margin-top: 3px;
        font-size: 10px !important;
    }
    }