.checkout-orderdetails-card{
    border-bottom: 1px solid $md-border;
    padding: 10px 0px;
    vertical-align: middle;
}

.orderdetails-image{
    height: 70px;
    width: 130%;
    object-fit: contain;
}

.orderdetails-btn{
    background-color: $primary;
    padding: 3px 5px;
    color: $white;
    font-size: 15px !important;
}

.order-actions{
    cursor: pointer;
    margin: 0px 10px;
    font-size: 15px !important;
    font-weight: bold !important;
}

.order-count{
    padding: 0px 10px;
}

.checkout-orders{
    .card{
        margin: 0px !important;
    }
    .havencard{
        margin: 0px !important;
    }
    .card-header{
        padding: 0px !important;
        padding-left: 10px !important;
        background-color: $white !important;
        border: none !important;
    }
    .btn:focus{
        box-shadow:none !important
    }
    .card-body{
        padding: 0px 25px !important;
        background-color: $white !important;
        border: none !important;
    }
    .accordion{
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        padding: 10px 10px !important;
    }
}

@media screen and (max-width:600px) {
    .orderdetails-btn{
        width:65%;
        position: relative;
        left: 5px;
    }
    
}