.order-details-page{
    .verticalBar{
        padding: 1%;
        font-size: 25px; 
        font-weight: 100; 
        color: #777;
      }
      
      .align-left{
        text-align: left;
      }
      
      .align-right{
        text-align: right;
      }
      
      .card-title{
        color: black;
        font-weight: bold;
        display: inline-block;
      }
      
      .fa-pencil-square-o{
        float: right;
      }
      
      .card-body{
        background-color: white;
      }
      
      .logo-header{
          font-size: 35px;
          margin-bottom: 0px !important;
      }
      .preview-img
      {
          width: 175px;
          height: 175px;
          border : 1px solid black;
      }
      .breadcrumb {
          border: none;
          padding-left: 0px;
          .breadcrumb-item {
              a{
                  color: #000000;
              } 
            &.active {
              color :#777;
            }
          }
      }
      
      .upload-form{
          margin-top: 10px;
          margin-bottom: 50px;
          padding: 35px 15px;
          -webkit-box-shadow: 0 0 4px rgb(218, 216, 216);
          box-shadow: 0 0 4px rgb(218, 216, 216);
          border-radius: 5px;
          width: 100%;
          background-color: #ffffff;
          @include media-breakpoint-down(sm){
              padding: 10px 30px;
              align-items: center;
              margin-bottom: 20px;
          }
      }
      
      .upload-form-label{
          float: right;
          margin-right: 10px;
          padding-top: 4px;
          color: #313131;
          @include media-breakpoint-down(sm){
              float: left;
              margin-right: none;
              padding-top: 0px;
          }
      }
      
      .upload-buttons{
          @include media-breakpoint-down(sm){
              padding-top: 15px;
          }
      }
      
      .form-control{
          background-color: white !important;
      }
      
      .copyright-section{
          display: block;
          margin-top:20px;
          margin-bottom: 20px;
      }
      select {
        &.form-control {
          padding: .4375rem .75rem;
          border: 0;
          outline: 1px solid $border-color;
          color: $input-placeholder-color;
          &:focus {
            outline: 1px solid $border-color;
          }
          @each $color,
          $value in $theme-colors {
            &.border-#{$color} {
              outline: 1px solid $value;
              &:focus {
                outline: 1px solid $value;
              }
            }
          }
        }
      }
      /* Checkboxes and Radios */
      
      .form-check {
          position: relative;
          display: block;
          margin-top: 10px;
          margin-bottom: 10px;
          padding-left: 1.25rem;
        
          .form-check-label {
            min-height: 18px;
            display: block;
            margin-left: 0.625rem;
            line-height: 1.5;
            color: $text-muted;
            .rtl & {
              margin-left: 0;
              margin-right: 1.75rem;
            }
        
            input {
              position: absolute;
              top: 0;
              left: 0;
              .rtl & {
                left: auto;
                right: 0;
              }
              margin-left: 0;
              margin-top: 0;
              z-index: 1;
              cursor: pointer;
              opacity: 0;
              filter: alpha(opacity=0);
            }
            input[type="checkbox"] {
              + .input-helper {
                &:before,
                &:after {
                  position: absolute;
                  top: 0;
                  left: 0;
                  .rtl & {
                    left: auto;
                    right: 0;
                  }
                }
                &:before {
                  content: "";
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  border: solid theme-color(primary);
                  border-width: 2px;
                  transition-duration: 0s;
                  -webkit-transition-duration: 250ms;
                  transition-duration: 250ms;
                }
          
                &:after {
                  transition-duration: 0s;
                  -webkit-transition-duration: 250ms;
                  transition-duration: 250ms;
                  font-family: 'Material Design Icons';
                  opacity: 0;
                  filter: alpha(opacity=0);
                  -webkit-transform: scale(0);
                  -ms-transform: scale(0);
                  -o-transform: scale(0);
                  transform: scale(0);
                  content: '\F12C';
                  font-size: .875rem;
                  font-weight: bold;
                  color: $white;
                }
              }
              &:checked {
                +.input-helper {
                  &:before {
                    background: theme-color(primary);
                    border-width: 0;
                  }
                  &:after {
                    width: 18px;
                    opacity: 1;
                    line-height: 18px;
                    filter: alpha(opacity=100);
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                    transform: scale(1);
                    color: black;
                  }
                }
              }
        
              &:disabled {
                + .input-helper {
                  &:before {
                    border-color: $border-color;
                  }
                }
        
                &:checked {
                  + .input-helper {
                    &:after {
                      background: $border-color;
                      color: $white;
                    }
                  }
                }
              }
            }
            input[type="radio"] {
              +.input-helper {
                &:before {
                  position: absolute;
                  content: "";
                  top: 0;
                  left: 0;
                  .rtl & {
                    left: auto;
                    right: 0;
                  }
                  border: solid theme-color(primary);
                  border-width: 2px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  transition-duration: 0s;
                  -webkit-transition-duration: 250ms;
                  transition-duration: 250ms;
                }
        
                &:after {
                  content: "";
                  width: 8px;
                  height: 8px;
                  background: $white;
                  border-radius: 50%;
                  top: 6px;
                  left: 6px;
                  .rtl & {
                    left: auto;
                    right: 6px;
                  }
                  -webkit-transition: all;
                  -o-transition: all;
                  transition: all;
                  transition-duration: 0s;
                  -webkit-transition-duration: 250ms;
                  transition-duration: 250ms;
                  opacity: 0;
                  filter: alpha(opacity=0);
                  -webkit-transform: scale(0);
                  -ms-transform: scale(0);
                  -o-transform: scale(0);
                  transform: scale(0);
                  position: absolute;
                }
              }
        
              &:checked {
                +.input-helper {
                  &:before {
                    background: theme-color(primary);
                    border-width: 0;
                  }
        
                  &:after {
                    opacity: 1;
                    line-height: 1.5;
                    filter: alpha(opacity=100);
                    -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                    -o-transform: scale(1);
                    transform: scale(1);
                  }
                }
              }
        
              &:disabled {
                + .input-helper {
                  &:before {
                    border-color: $border-color;
                  }
                }
        
                &:checked {
                  + .input-helper {
                    &:before {
                      background: $border-color;
                    }
        
                    &:after {
                      background: $white;
                    }
                  }
                }
              }
            }
          }
        }
}