.product-listing-card{
    background-color: #f3f2f8;
    border: none;
    border-radius: 20px;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.product-list-cards{
    background-color: #f3f2f8;
    border:none;
    border-radius: 10px;
    width: 100%;
    min-height: 400px !important;
    max-height:600px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);

}

.tams-product-list-cards{
    background-color: white;
    border:none;
    border-radius: 10px;
    width: 90%;
    min-height: 356px !important;
    max-height:600px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);

}



.product-list-cards1{
    border:none;
    border-radius: 10px;
    // width: 100%!important;
    min-width: 300px !important;
    max-width:300px !important;
    min-height: 400px !important;
    max-height:600px !important;
    box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;
    padding-top: 30px;

}
.product-list-cards-deera{
    border:none;
    border-radius: 10px;
    // width: 100%!important;
    min-width: 300px;
    max-width:300px;
    min-height: 400px;
    max-height:600px;
    box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;
    padding-top: 30px;

}

.product-list-cards-avonseals{
    border:none;
    border-radius: 10px;
    // width: 100%!important;
    min-width: 80%;
    max-width:100%;
    min-height: 100%;
    max-height:100%;
    box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;
  // padding-top: 30px;
    
    
    
    
    }

.product-list-cards-avonseals1 {

    margin-left: 10%;
    min-height: 90%;
    min-width: 75%;
    max-width: 85%;
    margin-left: 10%;
    box-shadow: 0 2px 14px #0003, 0 4px 14px #00000030;
    max-height: 100%;
    
    }





// .bestgifts-list-cards{
//     border:none;
//     border-radius: 10px;
//     min-width: 300px !important;
//     max-width:300px !important;
//     min-height: 400px !important;
//     max-height:600px !important;
//     box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
//     padding-top: 30px;

// }

@media (max-width: 1920px) {
    .product-list-cardskub{
        border-right: 1px solid;
        border-bottom: 1px solid;
        
    
    }
    .bestgifts-list-cards{
        border:none;
    border-radius: 10px;
     min-width: 300px !important;
     max-width:300px !important;
    min-height: 400px !important;
    max-height:600px !important;
     box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2), 0 4px 14px 0 rgba(0, 0, 0, 0.19);
    padding-top: 30px;
    
    }
    .bestgifts-list-cards1{
      justify-content: space-between !important;
    }

}


@media (min-width: 360px) and (max-width: 600px) {
    .product-list-cardskub{
        border-right: 1px solid;
        border-bottom: 1px solid;
        
    
    }
    .bestgifts-list-cards{
        border:none;
        border-radius: 0px;
        min-width: 156px !important;
        max-width:156px !important;
        min-height: 260px !important;
        max-height:260px !important;
        box-shadow:none;
        padding-top: 30px;
    }

    .bestgifts-list-cards1{
        justify-content: center !important;
    }
}

.cartButton_bb{
    font-size: small;
    font-weight: bold;
    height: 30px;
    width: 45%;
    margin-left: 25%;
    margin-top: 4px;
    border: 3px!important;
    color: white !important;
    background-color: #e7762d;
    border-radius: 20px
}
.enqButton{
    font-size: small;
    font-weight: bold;
    height: 30px;
    width:45%;
    margin-left:25%;
    margin-top:4px;
    background-color: transparent !important;
    border:3px solid #3c707c !important;
    color: #091214 !important;
    background-color:grey;
}
.inner-star-icon{
    font-size:15px!important;
    color: orange!important;
    white-space: nowrap;
}
@media screen  and (max-width: 600px){
    .cartButton_bb{
        font-size: 10px;
        font-weight: bold;
        width: 85%;
        height: 25px;
        margin-left: 5%;
        margin-top: 2px;
        background-color: #e7762d !important;
        border: 2px;
        color: white !important;
        position: relative;
        top: -1px;
    }
    .enqButton{
        font-size: 10px;
        font-weight: bold;
        width:85%;
        height:25px;
        margin-left:5%;
        margin-top:2px;
        background-color: transparent !important;
        border:2px solid #3c707c !important;
        color: #091214 !important;
        background-color:grey;
    }
    .product-list-cards1{
        border:none;
        border-radius: 10px;
        min-width: 100px !important;
        max-width:200px !important;
        min-height: 315px !important; max-height:300px !important;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
        padding-top: 30px;
    }
    .product-list-cards-deera{
        min-width: 0px !important;
        max-width:100% !important;
        min-height: 0px !important; 
        max-height:150px !important;
        padding: 0px;
        width: 100%;
        display: flex;
        box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
        margin-bottom: 10px;
    }
    .product-list-cards-avonseals{
        min-width: 0px !important;
        max-width:100% !important;
        min-height: 0px !important; 
        max-height:120px !important;
        padding: 0px;
        width: 100%;
        display: flex;
        box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
        margin-bottom: 10px;
    }
    .product-list-cards-avonseals1{
        min-width: 0px !important;
        max-width:85% !important;
        min-height: 0px !important; 
        max-height:133px !important;
        padding: 0px;
        width: 100%;
        display: flex;
        box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
        margin-bottom: 10px;
        transform: translate(0px,-30px);
    }
    .product-list-price11{
        font-size: 12px !important;
       color: #e7762d !important;
       font-weight: 600 !important;
    }
    .product-list-image1{
        min-height: 150px !important;
        max-height: 150px !important;
        width: 85% !important;
        border-radius: 5px !important;
        object-fit: contain;
    }
    .inner-star-icon{
        font-size:11px!important;
        color: orange!important;
    }
    .fa fa-shopping-cart{
        font-size:11px!important;
    }
}



.seller{
    width:150px;
    height:30px;
    background-color:#2554C7;
    padding:5px;
    border-radius:4px;
    color:#fff;
    font-size:14px;
    
}


.mayan-product-list-cards{
    background-color:white;
    border:none;
    border-radius: 10px;
    width: 100%; 
    min-height:314px !important;
    max-height:336px !important;
    border: solid;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);

}
.mayan_img_center{
    
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
      
}
@media screen and (max-width: 600px){
    .mayan-product-list-cards{
   background-color:white;
    border:none;
    border-radius: 10px;
    width: 100%;
    min-height: 150px !important;
    max-height:150px !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
    display: flex !important;
    // position: relative;
    // bottom: 20px;
    
    }


    .mayan_img_center{
    
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        min-width: 148px;
      
}
    
}
.btn-default{
    background-color: red;
}
.text-for-listing {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    right: -3px;
    top: -5px;
    font-size: 1rem;
    text-align: center;
    color: #777;
    border-radius: 50%;
    // background: #777;
    cursor: pointer;
    transition:all 0.5s;
  }

  .info-class{
      visibility: visible;
  }
    .product-info-list{
        visibility: hidden;
        font-weight: bold;
    }

//   .info-class{
//       visibility: visible;
//   }
//     .product-info-list{
//         visibility: hidden;
//         font-weight: bold;
//     }

//   .text-for-listing:hover{
//       color: black;
//     width: 100%;
//     height: 370px;
//     right: 0px;
//     top: 0px;
//     border-radius: 5px;
//     background: rgba($primary,.2);
//     transform:all 0.5s;
//     .info-class{
//         visibility: hidden;
//     }
//       .product-info-list{
//           visibility: visible;
//       }
//   }

// .product-list-image{
//     max-height: 250px;
//     width: 100%;
//     border-radius: 20px;
//     border-bottom-right-radius: 0px !important;
//     border-bottom-left-radius: 0px !important;
// }

// .product-list-description{
//     padding: 5px 15px !important;
//     min-height: 120px !important;
// }

// .product-list-title{
//     font-size: 16px !important;
//     font-weight: bold;
// }

// .product-list-price{
//     color: #ec6014 !important;
//     font-weight: bolder !important;
// }

// .product-button-card{
//     margin-top: 15px !important;
//     border-radius: 20px !important;
//     background-color: #ec6014 !important;
//     color: white;
//     padding: 5px 25px !important;
//     border-color: #ec6014 !important;
// }

.product-listing-container{
    margin: 10px 20px !important;
}

.product-list-card{
    background-color: #ffffff !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    border: none !important;
    height: auto !important;
}

.product-list-image{
    min-height: 230px !important;
    max-height: 230px !important;
    width: 100% !important;
    border-radius: 10px !important;
    object-fit: contain;
}


.product-list-description{
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
}

.product-list-tile{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.product-list-tile-mayan{
    font-size: 13px !important;
    color: #3c707c !important;
    font-weight: 600 !important;
   // height: 35px;
    
}
@media screen and (max-width: 600px){
    .product-list-tile-mayan{
        font-size: 11px !important;
        color:black !important;
        font-weight: 600 !important;
        
        
    }
    .mayan-product-list-price{
        font-size: 14px !important;
        color: #3c707c !important;
        font-weight: 600 !important;
        
    }
    .unchecked {
        color: #3c707c  !important;
        font-size: 12px;
    }
}


.product-list-price{
    font-size: 16px !important;
    color: #d54604 !important;
    font-weight: 600 !important;
}

.mayan-product-list-price{
    font-size: 13px ;
    color: #3c707c ;
    font-weight: 600 !important;
    position: relative;
    top: 10px;
}

.product-weight-card{
    padding: 2px 10px !important;
    border: 1px solid $primary !important;
    margin: 3px !important;
    border-radius: 50%;
    // box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
    color: $primary !important;
    cursor: pointer !important;
}

.product-weight-card:hover{
    background-color: $primary !important;
    color: $white !important;
    box-shadow: 0 4px 8px 0 rgba(88, 88, 88, 0.2), 0 6px 20px 0 rgba(88, 88, 88, 0.2) !important;
}

.product-list-cart{
    font-size: 13px !important;
    // color: #255763 !important;
    color: $primary !important;
    font-weight: bold !important;
    cursor: pointer;
    // background-color: #255763 !important;
    // border-radius: 10px !important;
    // padding: 10px 25px !important;
}

.natural-main-text{
    color:$primary !important;
    font-family: 'Segoe UI' !important;
    font-size: 19px !important;
    font-style: italic !important;
    padding-top: 15px !important;
    // animation: glow 2s ease-in-out infinite alternate;
    @keyframes glow {
        from {
          text-shadow: 0 0 20px #32cd32;
        }
        to {
          text-shadow: 0 0 30px #32cd32, 0 0 10px #32cd32;
        }
      }
}

.tams-user-icon{
    i.fa{
        padding: 0px !important;
    }
    // padding-top: 15px !important;
    border: 1px solid #777 !important;
    color: #777 !important;
    padding: 5px 9px !important;
    border-radius: 50%;
    margin-right: 5px !important;
}