@mixin btn-primary-mx($bg-color, $bd-color, $bg-radius){
    background: $bg-color !important;
    border-color: $bd-color !important;
    border-radius: $bg-radius !important;
}

@mixin btn-secondary-mx($bg-color, $bd-color, $color, $bg-radius){
    background: $bg-color !important;
    border-color: $bd-color !important;
    border-radius: $bg-radius !important;
    color: $color !important;
}