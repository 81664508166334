.cart-nw-img{
    height: 200px;
    width: 300px;
    padding-bottom: 25px;
}

.cart-new-container{
    padding: 30px 20px;
}

.cart-em-header{
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 20px;
}

.cart-mn-container{
    margin: 40px 50px !important;
}

.cart-new-card{
    // padding: 10px 20px !important;
    height: 160px !important;
}

.cart-new-image{
    height: 160px;
    width: 100%;
}

.cart-footer{
    height: 40px;
    border-top: 1px solid rgb(212, 211, 211);
    padding-left: 1.25rem;
}

.cart-body{
    height: 120px;
    padding: 1.2rem 1rem;
    overflow-y: auto;
    padding-bottom: 0.5rem;
}

.cart-a{
    border-right: 1px solid rgb(212, 211, 211);
}

.cart-btn{
    color: #777 !important;
    font-size: 0.85rem !important;
    padding-top: 8px !important;
    cursor: pointer !important;
}

.cart-b{
    padding-top: 8px; 
    color: $menu-txt-color;
}

.cart-customize{
    color: $menu-txt-color;
    font-size: 0.85rem !important;
    float: right;
    cursor: pointer !important;
}

.cart-customize:hover{
    font-weight: bold;
}