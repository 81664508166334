
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.thumb {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
   
}
.thumb1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 20px
}
.thumb1-deera {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 20px
}
.thumb1-avon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-left: 20px
}
.div-only-mobile{
  display: none;
  }
  .desk{
   display: block;
    }
.thumbkub {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
 // margin-left: 20px
   
}
.deera-img{
  text-align: center;
}
.deera-atc{
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-left: 25%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #3c707c !important;
  color: #091214 !important;
  background-color: grey;
}
.avon-atc{
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-left: 25%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #f33601 !important;
  color: #091214 !important;
  background-color: grey;
}
@media only screen and (max-width: 768px) {
.thumbkub{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //margin-left: 20px
  
  }
 .thumb1{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 20px
  
  }
  .thumb{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important
  }
  .thumb1-deera{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important
  }
  .thumb1-avon{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important
  }
  .desk{
    display: none;
    }
   .div-only-mobile{
    display: block;
    }
  .deera-img{
    width: 40%;
  }
  .product-list-description-deera{
    width:60%;
  }
  .deera-atc{
    width:60%;
  }
  .review-mob1{
    width: 41%;
    transform: scale(0.8);
    height: 10px;
  }
  .review-mob{
    width: 75%;
    transform: scale(0.8);
    height: 10px;
  }
  .deera-container{
    width: 100%;
  }
}
@media  screen and (max-width:391px) {
  .deera-atc{
    width:70%;
  }
  
}
.thumb-content{
  text-align: left;
}
.price-area{
  margin-top: 4%;
}
.cart-buttons{
  float: right;
}
.product-description {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.product-description-bh {
  width: 250px;
}
@media (max-width: 1920px)  {
  .product-list-price1{
    // font-size: 20px ;
    font-size: 18px ;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 360px) and (max-width: 600px) {
  .product-list-price1{
    // font-size: 20px ;
    font-size: 14px ;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
}
.product-list-tile1{
  color: #0497a6;
  font-size: 14px !important;
  //color: #000 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  // background-image: linear-gradient(
  //     -225deg,
  //     #231557 0%,
  //     #44107a 29%,
  //     #ff1361 67%,
  //     #fff800 100%
  //   );
  //   background-size: auto auto;
  //   background-clip: border-box;
  //   background-size: 200% auto;
  //   color: #fff;
  //   background-clip: text;
  //   text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   animation: textclip 2s linear infinite;
    display: inline-block;
        font-size: 190px;
}
.product-list-tile-deera{
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}
.product-list-tile-avon{
  font-size: 16px !important;
  color: #c50 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

.product-list-tilekub{
  height:1.5vw;
  font-family: FontAwesome;
  font-size: 18px!important;
  color: #000!important;
  font-weight: 200!important;
  overflow: hidden!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 2!important;
  -webkit-box-orient: vertical!important;
  text-overflow: ellipsis!important;
  //min-width: max-content;
}
.product-list-tilevimpro{
  white-space: nowrap;
  font-family: FontAwesome;
  font-size: 20px!important;
  color: #2554C7!important;
  font-weight: 200!important;
  overflow: hidden!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 2!important;
  -webkit-box-orient: vertical!important;
  text-overflow: ellipsis!important;
  //min-width: max-content;
}
@media screen  and (max-width: 768px){
  .product-list-tilevimpro{
    font-family: FontAwesome;
    font-size: 18px!important;
    color: #2554C7!important;
    font-weight: 600!important;
    overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2!important;
    -webkit-box-orient: vertical!important;
    text-overflow: ellipsis!important;
    //min-width: max-content;
  }
  .product-list-tilekub{
    font-family: FontAwesome;
    font-size: 30px!important;
    color: #2554C7!important;
    font-weight: 200!important;
    overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2!important;
    -webkit-box-orient: vertical!important;
    text-overflow: ellipsis!important;
    height: 38px;
    //min-width: max-content;
  }
  .product-list-tile1{
    color: #0497a6;
    font-size: 13px !important;
    //color: #000 !important;
    font-weight: 700 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    height: 38px;
    text-transform: uppercase;
    // background-image: linear-gradient(
    //   -225deg,
    //   #231557 0%,
    //   #44107a 29%,
    //   #ff1361 67%,
    //   #fff800 100%
    // );
    // background-size: auto auto;
    // background-clip: border-box;
    // background-size: 200% auto;
    // color: #fff;
    // background-clip: text;
    // text-fill-color: transparent;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // animation: textclip 2s linear infinite;
    display: inline-block;
        font-size: 190px;
    //text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px grey;
  }
  
}
@media (min-width: 780px) and (max-width: 1920px) {

  .product-list-descriptionkub{
    min-width: max-content;
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
   // margin-top: -35px;
  }
}
@media (max-width: 1920px) {
  .product-list-tile2{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }

  .product-list-price2{
    font-size: 20px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }

  .rating1{
    font-size: 15px !important;
  }

}


@media (min-width: 360px) and (max-width: 600px) {
  .product-list-descriptionkub{
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
   // margin-top: -35px;
  }
  .product-list-tile2{
    font-size: 12px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }

  .product-list-price2{
    font-size: 12px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }

  .rating1{
    font-size: 12px !important;
  }
}

.product-list-description1{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}
.product-list-description-deera{
  padding: 10px 15px;
  margin-bottom: 10px;
}
.product-list-descriptionkub{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  margin-top: -35px;
}
.product-list-image1{
  min-height: 270px !important;
  max-height: 270px !important;
  width: 100% !important;
  // width: 60% !important;
  border-radius: 10px !important;
  object-fit: contain;
  
}
.product-list-image-deera{
  min-height: 270px ;
  max-height: 270px ;
  width: 100% ;
  // width: 60% !important;
  border-radius: 10px ;
  object-fit: contain;
  
}
.product-list-image-avonseals{
  min-height: 450px ;
  max-height: 275px ;
  width: 100% !important ;
  // width: 60% !important;
  border-radius: 10px ;
  object-fit: contain;
  
}
.product-list-imagekub{

  max-height: 240px!important;
  width:80%!important;
  border-radius: 10px!important;
  object-fit: contain;
}
.listing{
 border-radius: 6px !important;
 padding: 5px 20px !important;
        
  border: none !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.collection-filter {

  background-color: #fff;
  .filter-top {
    border-bottom: 1px solid #dddddd;
    .filter-icon{
      display: none;
    }
    .filter-clear {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
  .layerd-navigation {
    padding: 10px;
    // font-family: "Inter-Regular";
    border-bottom: 1px solid #dddddd;
    .collection-collapse-block {
      margin-bottom: 0px;
      .collection-collapse-block-content {
        padding-left: 30px;
        .options {
            margin: 8px 0px 8px 0px;
            input {
                margin-right: 10px;
            }
            label{
                margin-bottom: 0px;
                font-size: 15px;
                .count {
                    color: #afabab;
                    font-size: 14px;
                }
            }
        }
      }
    }
  }
}
@media screen and (max-width:768px) {
  .filter-top{
    float: right;
    position: absolute;
    left: 75%;
    margin-top: -10px;
    top: 0%;
    border: none !important;
    .filter-icon{
      display: inline !important;
    }
  }
  .thumb {
    grid-template-columns: repeat(1, 1fr);
    width:111%;
    margin-left:-25px;
  }
  .product-description {
    width: 100% !important;
    display: grid;
    white-space: break-spaces;
  }
  
}
@media (max-width: 1920px) {
  .cartButton{
    font-size: small;
    font-weight: bold;
    width: 45%;
    margin-left: 25%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButton1{
    font-size: small;
    font-weight: bold;
    width:152%;
    margin-left:99%;
    margin-top:4px;
    text-align: center;
  }
  .product-list-image1{
  min-height: 270px !important;
  max-height: 270px !important;
  width: 100% !important;
  border-radius: 10px !important;
  object-fit: contain;
  }
}


@media (min-width: 360px) and (max-width: 600px) {
  .cartButton{
    font-size: small;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButton1{
    font-size: small;
    font-weight: bold;
    width:152%;
    margin-left:0%;
    margin-top:4px;
    text-align: center;
  }
  .product-list-image1{
  min-height: 170px !important;
  max-height: 170px !important;
  width: 100% !important;
  border-radius: 10px !important;
  object-fit: contain;
  }
  .product-list-image-deera{
    min-height: 150px ;
    max-height: 150px;
    width: 100%;
    height: 150px;
    }
    .product-list-image-avonseals{
      min-height: 119px ;
      max-height: 200px;
      width: 100%;
      height: 100px;
      }
}

@media  screen and (max-width:640px) {
.avon-atc{
 
  width: 74%;
 
}
}