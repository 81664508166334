.shipping-card{
    font-size: 12px !important;
    background-color: $white;
}

.shipping-card-active{
    background-color: $info-bg;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.clickable-tag{
    font-size: 14px !important;
    color: $primary;
    cursor: pointer;
    font-weight: 400;
}

.add-new-address{
    padding: 15px 25px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 5px;
}

.shipping-font{
    font-size: 14px !important;
}

.shipping-font1{
    font-size: 14px !important;
}