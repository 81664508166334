.custom-pointer{
    cursor: pointer;
}

.none-style{
    text-decoration: none !important;
}

dp-date-picker.dp-material .dp-picker-input {
    font-size: 1rem !important; 
    outline: none !important;
    border: none !important;
}

.contact-active.active{
    color: $menu-underline-color !important;
}

.contact-active:hover{
    color : $primary !important;
  }

  .outer-banner {
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;
  }
  .outer-banner > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
  .outer-banner .below-banner {
    z-index: 1;
    background-color: #f9f9f9;
    width: 100%;
  }
  .outer-banner .top-banner {
    z-index: 2;
    width: 100%;
  }

  .banner-img2{
    width: 90%;
    height: 400px;
    margin-left: -45%;
    border: 1px solid #ccc;
  }
  .carousel-indicators > li {
    border-radius: 50% !important;
    height:15px !important;
    width:15px !important;
    margin: 10px !important;
  }