.bs4-order-tracking {
    margin-bottom: 30px;
    overflow: visible;
    color: #878788;
    padding-left: 0px;
    margin-top: 30px
  }
  
  .bs4-order-tracking li {
    list-style-type: none;
    font-size: px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: #878788;
    text-align: center
  }
  
  .bs4-order-tracking li:first-child:before {
    margin-left: 15px !important;
    padding-left: 11px !important;
    text-align: left !important
  }
  
  .bs4-order-tracking li:last-child:before {
    margin-right: 5px !important;
    padding-right: 11px !important;
    text-align: right !important
  }
  
  .bs4-order-tracking li>div {
    color: #fff;
    width: 29px;
    text-align: center;
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #878788;
    border-radius: 50%;
    margin: auto
  }
  
  .bs4-order-tracking li:after {
    content: '';
    width: 150%;
    height: 2px;
    background: #878788;
    position: absolute;
    left: 0%;
    right: 0%;
    top: 15px;
    z-index: -1
  }
  
  .bs4-order-tracking li:first-child:after {
    left: 50%
  }
  
  .bs4-order-tracking li:last-child:after {
    left: 0% !important;
    width: 0% !important
  }
  
  .bs4-order-tracking li.active {
    font-weight: bold;
    color: #46c35f
  }
  
  .bs4-order-tracking li.active>div {
    background: #46c35f
  }
  
  .bs4-order-tracking li.active:after {
    background: #46c35f
  }
  
.card-timeline {
    background-color: #fff;
    z-index: 0
}
.timelineCard {
    background: transparent;
    cursor: default;
    transform-style: preserve-3d;
    transition: transform 0.4s ease 0s;
    width: 100%;
    -webkit-animation: giro 1s 1;
    animation: giro 1s 1; border: none;
  }

.order-traccking-st{
  padding-left: 30px;
  .hh-grayBox {
    background-color: #F8F8F8;
    margin-bottom: 20px;
    padding: 35px;
    margin-top: 20px;
  }
  .pt45{padding-top:45px;}
  .order-tracking{
    text-align: center;
    width: 19%;
    position: relative;
    display: block;
  }
  .order-tracking .is-complete{
    display: block;
    position: relative;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 0px solid #AFAFAF;
    background-color: #f7be16;
    margin: 0 auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    z-index: 2;
  }
  .order-tracking .is-complete:after {
    display: block;
    position: absolute;
    content: '';
    height: 14px;
    width: 7px;
    top: -2px;
    bottom: 0;
    left: 5px;
    margin: auto 0;
    border: 0px solid #AFAFAF;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  .order-tracking.completed .is-complete{
    border-color: $primary;
    border-width: 0px;
    background-color: $primary;
  }
  .order-tracking.completed .is-complete:after {
    border-color: #fff;
    border-width: 0px 3px 3px 0;
    width: 7px;
    left: 11px;
    opacity: 1;
  }
  .order-tracking p {
    color: #A4A4A4;
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
  }
  .order-tracking p span{font-size: 11px;}
  .order-tracking.completed p{color: #000;}
  .order-tracking::before {
    content: '';
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: #f7be16;
    top: 13px;
    position: absolute;
    left: calc(-50% + 20px);
    z-index: 0;
  }
  .order-tracking:first-child:before{display: none;}
  .order-tracking.completed:before{background-color: $primary;}
  
}
.order-details-container{
  display: flex;
  width: 33%;
}
.order-details-title{
  margin-left:5%;
  width: 100%;
  height:80px
}
.war-mob{
	width: 60%!important;
    margin-left: 20%;
}

@media screen and (max-width:600px) {
  .order-traccking-st{
    padding-left: 0px !important;
  }
  .order-details-container{
    width: 100%;
  }
  .order-details-title{
    height:40px
  }
  .war-mob{
		width: 50%!important;
    margin-left: 25%;
	}
	
}