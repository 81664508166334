.floating-forms{
  margin-top: 20px;
  margin-right: 25px;
  font-size: 12px;
  .invalid-feedback{
    font-size: 12px !important;
  }
    /****  floating-Lable style start ****/
    .floating-label { 
      position:relative; 
      margin-bottom:25px;
      margin-top: 20px;
    }
    .floating-select {
      font-size: 14px;
      padding:4px 0px;
      display:block;
      width:100%;
      height:40px;
      background-color: transparent;
      border:none;
      border-bottom:1px solid #ddd;
    }

    .floating-input{
      font-size: 14px;
      padding:4px 0px;
      display:block;
      width:100%;
      height:30px;
      background-color: transparent;
      border:none;
      border-bottom:1px solid #ddd !important;
    }
    
    .floating-input:focus , .floating-select:focus {
      outline:none;
      border-bottom:1px solid #ddd; 
    }

    .form-control{
      border-bottom:1px solid #ddd !important; 
  }
  .form-control:focus{
    border-bottom:1px solid #ddd !important; 
  }
    
    label {
      font-size:0.825em;
      border-bottom: none !important;
      color: $light-ash;
      font-weight:600;
      position:absolute;
      pointer-events:none;
      left:5px;
      top:5px;
      transition:0.2s ease all; 
      -moz-transition:0.2s ease all; 
      -webkit-transition:0.2s ease all;
    }
    
    .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label {
      top:-18px;
      color:$primary;
    }
    
    .floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
      top:-18px;
      color:$primary;
    }
    
    /* active state */
    .floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
      width:50%;
    }
    
    *, *:before, *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    
    .floating-textarea {
        min-height: 30px;
        max-height: 260px; 
        overflow:hidden;
      overflow-x: hidden; 
    }
    
    /* highlighter */
    .highlight {
      position:absolute;
      height:50%; 
      width:100%; 
      top:15%; 
      left:0;
      pointer-events:none;
      opacity:0.5;
    }
    
    /* active state */
    // .floating-input:focus ~ .highlight , .floating-select:focus ~ .highlight {
    //   -webkit-animation:inputHighlighter 0.3s ease;
    //   -moz-animation:inputHighlighter 0.3s ease;
    //   animation:inputHighlighter 0.3s ease;
    // }
    
    // /* animation */
    // @-webkit-keyframes inputHighlighter {
    //     from { background:$primary; }
    //   to 	{ width:0; background:transparent; }
    // }
    // @-moz-keyframes inputHighlighter {
    //     from { background:$primary; }
    //   to 	{ width:0; background:transparent; }
    // }
    // @keyframes inputHighlighter {
    //     from { background:$primary; }
    //   to 	{ width:0; background:transparent; }
    // }
      
}

.custom-datepicker{
  label {
    font-size:0.825em;
    border-bottom: none !important;
    color: $primary;
    font-weight:600;
    pointer-events:none;
  }
  .dp-input-container{
    border-bottom: 1px solid #ddd !important;
    font-size: 14px !important;
  }
  dp-date-picker.dp-material .dp-picker-input{
    font-size: 14px !important;
    color: #495057 !important;
  }
  .form-control{
    border: none;
    font-size: 14px !important;
    padding: 0px !important;
  }
}