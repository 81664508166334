.aboutus-img{
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.ew-wrapper{
    margin-top: 20px !important;
    border-top-width: 20px !important;
    padding-top: 20px !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px !important;
}

.ew-header{
    color: #bdc72c;
    text-align: left;
    font-style: normal;
    margin-bottom: 20px;
}

.para-container{
    padding-right: 18px !important;
    margin: 0 0 18px 0px !important;
    overflow-wrap: break-word;
    font-size: 14px;
    line-height: 24px;
    color: #555;
}

.e3s-img{
    max-height: 40px !important;
}

.approach-container{
    background-color: #f4f4f4 !important;
    padding:25px 20px !important;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px !important;
}

.cv-container{

}

.left-cv-text{
    padding-top: 80px !important;
}

.right-cv-text{
    padding-bottom: 40px !important;
}

.bootom-caertificate{
    background-color: #48959F !important;
    width: 100%;
    padding:35px 80px;
    color: #ffffff !important;
    padding-bottom: 45px;
}